// images.js
import img1 from '../static/drawn_images/barbed-wire.gif';
import img2 from '../static/drawn_images/redline-engine.gif';
import img3 from '../static/drawn_images/redline-girl.gif';
import img4 from '../static/drawn_images/gits-kick.gif';
import img5 from '../static/drawn_images/gits-typing.gif';
import img6 from '../static/CIMG0894.JPG';
import img7 from '../static/CIMG1165.JPG';
import img8 from '../static/logo.png';
import img9 from '../static/drawn_images/CIMG0861.JPG';
import img10 from '../static/drawn_images/CIMG0888.JPG';
import img11 from '../static/drawn_images/CIMG0979.JPG';
import img12 from '../static/drawn_images/CIMG1001.JPG';
import img13 from '../static/drawn_images/CIMG1066.JPG';
import img14 from '../static/drawn_images/CIMG1124.JPG';
import img15 from '../static/drawn_images/CIMG1147.JPG';
import img16 from '../static/drawn_images/CIMG1154.JPG';
import img17 from '../static/drawn_images/CIMG1169.JPG';
import img18 from '../static/drawn_images/CIMG1190.JPG';
import img19 from '../static/drawn_images/zvalis_webdev.jpg';
import img20 from '../static/drawn_images/cm1.jpg';
import img21 from '../static/drawn_images/me1.jpg';

export const imagesArray = [img19, img5, img21, img9, img1,
                            img20, img10, img2, img18, img4,
                            img6, img17, img11, img16, img3,
                            img15, img7, img12, img13, img14,
                            img8]
